import $ from 'jquery';
import 'what-input';
import './modernizr-webp';
// import './custom';

window.jQuery = $;
// require('foundation-sites');

import './lib/foundation-explicit-pieces';


$(document).foundation();


$(".hero__button").click(function() {
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#products-container").offset().top-160
    }, 1000);
});

$(document).ready(function() {
    function changeSubmenuMargin(){
        if ($('#top-nav').hasClass('is-stuck')) {
            $('.menu__dropdown').css('margin-top', '1px');
        };

        if ($('#top-nav').hasClass('is-anchored')) {
            $('.menu__dropdown').css('margin-top', '16px');
        };

    }

    $('#tworzywa').hover(function () {
        changeSubmenuMargin();
    });

    $('body').scroll(function () {
        changeSubmenuMargin();
    });

    changeSubmenuMargin();
});

